import React, {useEffect, useState} from 'react';
import {getContent, getServerParams} from "../../services/content";
import {Link, useParams} from "react-router-dom";
import "./SingleVideo.scss";
import VideoIframe from "../video-iframe/VideoIframe";

function SingleVideo() {
    const [content, setContent] = useState([]);
    const {id} = useParams();
    const rewardedTagId = false;

    useEffect(() => {
        window.scrollTo(0, 0);

        getContent().then(items => {
            let d = items['video-list'].filter(el => el['video_id'] == id);
            d[0]['views'] += ' DOWNLOADS';

            setContent(d[0]);

            if(rewardedTagId !== false) {
                getServerParams(rewardedTagId).then(params => {
                    const INCENTIVE_NEW_WINDOW_DOMAIN = params[13];
                    const CLOUDFRONT_BACKUP_DOMAIN = params[4];
    
                    const links = document.querySelectorAll('.video-description a');
                    links.forEach((el, index) => {
                        el.addEventListener('click', (ev) => {
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
    
                            const serverParamsUrl = 'https://' + INCENTIVE_NEW_WINDOW_DOMAIN + '/?h=' + encodeURIComponent(window.location.hostname) + '&tid=' + rewardedTagId + '&cc=' + encodeURIComponent(window.btoa(JSON.stringify({
                                title: encodeURIComponent(ev.target['innerText']),
                                cd: rewardedTagId,
                                domain: encodeURIComponent(CLOUDFRONT_BACKUP_DOMAIN),
                                link: encodeURIComponent(ev.target['href'])
                            })));
                            window.open(serverParamsUrl);
                        });
                    });
                });
            }
        });

    }, []);

    return (content &&
        <div className="single-video-wrapper animate__animated animate__fadeIn">
            <div key={content['embed'] + content['title']} className="video-list-wrapper">
                <div className="video-left-block">
                    <div className="video-img" style={{backgroundImage: 'url("' + content['thumbnail'] + '")'}}/>
                </div>
                <div className="video-right-block">
                    <div className="video-title">{content['title']}</div>
                    <div className="video-downloads">
                        {content['views']}
                    </div>
                    <div className="video-description" dangerouslySetInnerHTML={{__html: content['description']}}></div>
                </div>
            </div>

            {/* IFRAME */}
            <VideoIframe embed={content['embed']}/>
            {/* END IFRAME */}
        </div>
    );
}

export default SingleVideo;
